/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { PrimaryButton } from 'components/uiLibrary/LinkButton';
import useTracking from 'components/Globals/Analytics';

import usePageContext from 'utils/hooks/usePageContext';

import classes from './BannerLinkCTA.module.scss';

export const VARIANTS = {
  WHITE: 'white',
  BLUE: 'blue',
  ORANGE: 'orange',
};

const BannerLinkCTA = ({
  variant = VARIANTS.WHITE,
  message,
  ctaLabel,
  autoHideCTALabel = false,
  linkProps,
  trackingData,
  onClick,
}) => {
  const { navigate } = usePageContext();
  const track = useTracking();

  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick();
    } else if (linkProps) {
      navigate.to(linkProps);
      navigate.scrollTo();
    }

    if (trackingData) {
      track.click(trackingData);
    }
  }, [navigate, linkProps, onClick, track, trackingData]);

  if (!message || !ctaLabel || (!linkProps && !onClick)) {
    return null;
  }

  return (
    <div
      className={classnames(classes.root, {
        [classes.root__white]: variant === VARIANTS.WHITE,
        [classes.root__blue]: variant === VARIANTS.BLUE,
        [classes.root__orange]: variant === VARIANTS.ORANGE,
      })}
      onClick={onClickHandler}
    >
      <Typography size={14} weight="bold">
        {message}
      </Typography>
      <PrimaryButton
        size="medium"
        isLink={!!linkProps}
        {...linkProps}
        rightIcon={<SpriteIcon icon="chevron_right" />}
        styles={{ root: classes.link }}
        title={ctaLabel}
        preventDefault
        onClick={onClick}
      >
        <span className={classnames(classes.link_text, { [classes.link_text__auto_hide]: autoHideCTALabel })}>
          {ctaLabel}
        </span>
      </PrimaryButton>
    </div>
  );
};

export default BannerLinkCTA;
